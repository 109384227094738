import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const NewTab: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M16.315 0v2.025h4.675l-8.365 8.43 1.435 1.435 8.35-8.41v4.635h2.025V0h-8.12Zm1.995 22.105H2.05V5.845h10.17v-2.05H0v20.36h20.36V11.91h-2.05v10.195Z"
    />
  </Icon>
);
