import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Calendar: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M24.695 5H22V3h-2v2h-8V3h-2v2H7.27C5.435 5.065 4 6.485 4 8.24v18.5C4 28.565 5.45 30 7.305 30H24.69c1.855 0 3.305-1.43 3.305-3.26V8.24c0-1.755-1.44-3.175-3.305-3.24h.005ZM26 26.74c0 .72-.56 1.26-1.305 1.26H7.305C6.56 28 6 27.46 6 26.74V14h20v12.74ZM26 12H6V8.24c0-.67.59-1.215 1.305-1.24H10v2h2V7h8v2h2V7h2.66c.755.025 1.34.57 1.34 1.24V12ZM15.935 24.36c-.69 0-1.17-.48-1.2-1.185H12.89c.075 1.89 1.305 3.15 3.09 3.15 1.875 0 3.135-1.41 3.135-3.54 0-2.655-1.545-3.495-3.42-3.54-.285 0-.57.03-.84.09v-1.89h3.78v-1.77h-5.52v5.415c.45-.06 1.125-.165 1.785-.195 1.095-.045 2.19.24 2.19 1.905 0 .93-.465 1.56-1.155 1.56Z"
    />
  </Icon>
);
