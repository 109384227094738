import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Horse: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M21.294 3v6.4l8.114 9.624.024.03a4.06 4.06 0 0 1-5.723 5.675l-.011-.01-2.083-1.703a7.603 7.603 0 0 1-3.14-.423c-1.367 1.79-2.169 4.444-2.169 7.174h-2c0-2.934.818-5.94 2.397-8.141a6.272 6.272 0 0 1-1.092-1.085l1.57-1.238c1.359 1.724 3.608 1.854 4.559 1.683a1 1 0 0 1 .81.21l2.407 1.97a2.059 2.059 0 0 0 2.909-2.868l-8.336-9.89a1 1 0 0 1-.236-.644V7.535l-2.22 2.027-.004.004-3.771 3.487v8.705h-2v-6.856l-1.062.981.063 8.867-2 .014-.05-7.044-.948.877v9.158h-2v-7.31l-2.444 2.26L1.5 21.236 15.722 8.09 21.294 3Z"
      clipRule="evenodd"
    />
  </Icon>
);
