import { GatsbyBrowser } from 'gatsby';

export { wrapRootElement } from './gatsby-shared';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  // Code to be executed on route change
  const eventData = {
    // Include any data you want to pass to the event listeners
    // Example: myContextData
    pathname: location.pathname.replace(/\//g, ''),
  };
  const event = new CustomEvent('pageChange', { detail: eventData });
  window.dispatchEvent(event);
};
