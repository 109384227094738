import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Menu: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M25.5 23a1 1 0 0 1 0 2h-19a1 1 0 0 1 0-2h19Zm0-8a1 1 0 0 1 0 2h-19a1 1 0 0 1 0-2h19Zm0-8a1 1 0 0 1 0 2h-19a1 1 0 0 1 0-2h19Z"
    />
  </Icon>
);
