import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ExpandSmall: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m19.765 19.5-3.675-3.67-3.675 3.67L11 18.09 16.09 13l5.09 5.09-1.415 1.41Z"
      clipRule="evenodd"
    />
  </Icon>
);
