import React, { FC } from 'react';
import { Link } from '../link/Link';

interface OnetrustCookiePreferenceButtonProps {
  buttonLabel: string;
}

export const OnetrustCookiePreferenceButton: FC<
  OnetrustCookiePreferenceButtonProps
> = ({ buttonLabel }) => {
  // Function to handle opening the OneTrust cookie preference center
  const handleOpenCookiePreferences = () => {
    if (window.OneTrust && window.OneTrust.ToggleInfoDisplay) {
      window.OneTrust.ToggleInfoDisplay(); // Call the function to open the cookie settings
    } else {
      console.error(
        'OneTrust is not defined or ToggleInfoDisplay is not available.'
      );
    }
  };

  // We return a Link component that acts as a button; onClick it triggers the OneTrust preference center.
  return <Link label={buttonLabel} onClick={handleOpenCookiePreferences} />;
};

export default OnetrustCookiePreferenceButton;
