import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Logout: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.27 23.545c-4.285 0-7.77-3.485-7.77-7.77s3.485-7.77 7.77-7.77c1.79 0 3.475.595 4.87 1.72l1.255-1.555A9.757 9.757 0 0 0 14.27 6C8.885 6 4.5 10.385 4.5 15.77s4.385 9.77 9.77 9.77c2.22 0 4.395-.77 6.125-2.165L19.14 21.82a7.681 7.681 0 0 1-4.87 1.72v.005Zm12.265-9.185-2.905-2.905-1.415 1.415 1.905 1.905h-9.85v2h9.85l-1.905 1.905 1.415 1.415 2.905-2.905 1.415-1.415-1.415-1.415Z"
      clipRule="evenodd"
    />
  </Icon>
);
