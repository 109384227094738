import { setCookie } from '@agria/utils/src/cookies';
import { getQueryString } from '@agria/utils/src/queryStrings';
import { GatsbyBrowser } from 'gatsby';

export interface TrackingParams {
  sskey?: string;
}

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  const eventData = {
    pathname: location.pathname.replace(/\//g, ''),
  };
  const event = new CustomEvent('pageChange', { detail: eventData });
  window.dispatchEvent(event);
};

export { wrapPageElement, wrapRootElement } from './gatsby-shared';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  // Future self this is also in: packages/themes/qab/src/context/QuoteContext.tsx
  const queryString = window?.location.search;

  const qs = getQueryString<TrackingParams>(queryString);

  if (qs?.sskey) {
    setCookie('sskey', qs.sskey, 30);
  }
};
