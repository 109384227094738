import { useAffiliateLookup } from '@agria/paws/src/hooks/useAffiliateLookup';
import { useGetCustomerDetails } from '@agria/paws/src/hooks/entra/useGetCustomerDetails';
import { useGetAffiliateTypes } from '@agria/paws/src/hooks/useGetAffiliateTypes';
import { useCustomerUpdateFlag } from '@agria/paws/src/hooks/entra/useCustomerUpdateFlag';
import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from 'react';
import { Affiliate, CustomerDetails } from '@agria/paws/src/types';
import {
  deleteHeaders,
  updateHeaders,
} from '@agria/paws/src/utils/updateHeaders';
import { navigate } from 'gatsby';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EventType } from '@azure/msal-browser';
import { encryptData, decryptData } from '../utils/cryptoUtils';
import { AffiliateUser, CustomerUser, NavItem, User } from '../types/types';
import {
  breederLinks,
  customerLinks,
  nonAffiliateVetLinks,
  rehomingLinks,
  vetLinks,
} from '../const/navigationItems';
import { myAccountLinks } from '../const/myAccountLinks';
import { getAffiliateCategoryByTypeId } from '../utils/getAffiliateCategoryByTypeId';

interface MyAccountContextType {
  affiliate: AffiliateUser | null;
  customer: CustomerUser | null;
  isPending: boolean;
  isUserAuthenticated: boolean;
  logout: () => void;
  navItems: NavItem[];
  setUser: (user: User) => void;
  user: User | null;
}

const MyAccountContext = createContext<MyAccountContextType | undefined>(
  undefined
);

export const useMyAccount = () => {
  const context = useContext(MyAccountContext);

  if (!context) {
    throw new Error('useMyAccount must be used within an MyAccountProvider');
  }

  return context;
};

interface MyAccountProviderProps {
  children: ReactNode;
}

export const MyAccountProvider: React.FC<MyAccountProviderProps> = ({
  children,
}) => {
  const [navItems, setNavItems] = useState<NavItem[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const [affiliate, setAffiliate] = useState<AffiliateUser | null>(null);
  const [customer, setCustomer] = useState<CustomerUser | null>(null);
  const { data: affiliateTypes } = useGetAffiliateTypes();
  const [isPending, setIsLoading] = useState<boolean>(false);
  const { mutate: sendCustomerFlag } = useCustomerUpdateFlag();
  const [isUserAuthenticated, setIsUserAuthenticated] =
    useState<boolean>(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const clearState = () => {
    sessionStorage.removeItem('user');
    deleteHeaders(['Authorization', 'X-ID-Token']);
    setCustomer(null);
    setAffiliate(null);
    setUser(null);
  };

  useEffect(() => {
    if (user && isAuthenticated) {
      setIsUserAuthenticated(true);
    } else if (user && user.AffiliateId) {
      setIsUserAuthenticated(true);
    } else {
      setIsUserAuthenticated(false);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const callbackId = instance.addEventCallback((message) => {
      if (message.eventType === EventType.LOGIN_SUCCESS) {
        const customerId = message?.payload?.idTokenClaims?.PAWSContactId;
        const userAccessToken = message?.payload?.accessToken;
        const userIdToken = message?.payload?.idToken;

        setUser({
          CustomerId: customerId,
          UserAccessToken: userAccessToken,
          UserIdToken: userIdToken,
        });

        navigate(myAccountLinks.dashboard);
      }

      if (message.eventType === EventType.LOGOUT_SUCCESS) {
        clearState();
        navigate('/');
      }
    });

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId);
      }
    };
  }, []);

  useEffect(() => {
    if (user?.UserIdToken && user?.UserAccessToken) {
      sendCustomerFlag({
        customerId: user.CustomerId,
        flagId: '11388D3A-0257-4A55-B8BC-C67B7B237077',
      });
    }
  }, [user?.CustomerId, user?.UserIdToken, user?.UserAccessToken]);

  const {
    data: customerData,
    fetchCustomerDetails,
    isError: customerError,
    isPending: customerIsLoading,
  } = useGetCustomerDetails();

  const {
    mutate: getAffiliateDetails,
    data: affiliateData,
    isError: affiliateError,
    isPending: affiliateLoading,
  } = useAffiliateLookup();

  useEffect(() => {
    // if (loginLoading || affiliateLoading) {
    if (customerIsLoading || affiliateLoading) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [affiliateLoading, customerIsLoading]);
  // }, [affiliateLoading, loginLoading, customerIsLoading]);

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    const storedUserDecrypted: User | null = storedUser
      ? (decryptData(storedUser) as User)
      : null;

    if (storedUserDecrypted) {
      if (storedUserDecrypted.CustomerId) {
        fetchCustomerDetails(storedUserDecrypted.CustomerId);
      } else {
        getAffiliateDetails({ agriaId: storedUserDecrypted.AgriaId });
      }

      setUser(storedUserDecrypted);
    }
  }, []);

  const setCurrentCustomer = (data: CustomerDetails) => {
    const customerWithFullName: CustomerUser = {
      ...data,
      FullName: `${data?.FirstName} ${data?.LastName}`,
      FirstName: `${data?.FirstName}`,
    };

    setCustomer(customerWithFullName);

    setNavItems(customerLinks);
  };

  const setCurrentAffiliate = (data: Affiliate | undefined) => {
    if (affiliateTypes) {
      const affiliateWithFullName: AffiliateUser = {
        ...data,
        FullName: `${data.Forename} ${data.Surname}`,
        FirstName: `${data.Forename}`,
        AffiliateType:
          affiliateTypes.find((type) => type.Id === data.AffiliateTypeId)
            ?.Name || 'undefined',
        AffiliateCategory: getAffiliateCategoryByTypeId(
          data.AffiliateTypeId,
          data.AppointedRepresentativeStatusId,
          affiliateTypes
        ),
      };

      setAffiliate(affiliateWithFullName);

      if (affiliateWithFullName.AffiliateCategory === 'Non-affiliate Vet') {
        setNavItems(nonAffiliateVetLinks);
      }

      if (affiliateWithFullName.AffiliateCategory === 'Vet') {
        setNavItems(vetLinks);
      }

      if (
        affiliateWithFullName?.AffiliateCategory === 'Charity' ||
        affiliateWithFullName?.AffiliateCategory === 'Rehoming'
      ) {
        setNavItems(rehomingLinks);
      }

      if (affiliateWithFullName?.AffiliateCategory === 'Breeder') {
        setNavItems(breederLinks);
      }
    }
  };

  useEffect(() => {
    if (user) {
      sessionStorage.setItem(
        'user',
        encryptData(JSON.stringify(user)) as string
      );

      if (user.CustomerId === customerData?.Id) {
        setCurrentCustomer(customerData);
      } else if (user.CustomerId) {
        fetchCustomerDetails(user.CustomerId);
      } else if (user.AgriaId === affiliateData?.AgriaId) {
        setCurrentAffiliate(affiliateData);
      } else {
        getAffiliateDetails({ agriaId: user.AgriaId });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user) {
      if (user.UserAccessToken && user.UserIdToken) {
        updateHeaders([
          {
            Authorization: `bearer ${user.UserAccessToken}`,
          },
          { 'X-ID-Token': user.UserIdToken },
        ]);
      }
    }
  }, [user]);

  useEffect(() => {
    if (affiliateData && !affiliateError && affiliateTypes) {
      setCurrentAffiliate(affiliateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [affiliateData, affiliateTypes, affiliateError]);

  useEffect(() => {
    if (customerData && !customerError) {
      setCurrentCustomer(customerData);
    }
  }, [customerData, customerError]);

  // Handle logout

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const logout = () => {
    if (isAuthenticated) {
      instance.logoutRedirect();
    } else {
      navigate('/');
      clearState();
    }
  };

  // Set context value

  const contextValue = useMemo(
    () => ({
      affiliate,
      customer,
      isPending,
      isUserAuthenticated,
      logout,
      navItems,
      setUser,
      user,
    }),
    [
      affiliate,
      customer,
      isPending,
      isUserAuthenticated,
      logout,
      navItems,
      setUser,
      user,
    ]
  );

  return (
    <MyAccountContext.Provider value={contextValue}>
      {children}
    </MyAccountContext.Provider>
  );
};
