import React from 'react';
import { siteLinks } from '@agria/theme/src/const/siteLinks';
import { ButtonProps } from '@agria/theme/src/components/button';
import { Plus } from '@agria/theme/src/components/icon';

//
// CUSTOMERS
//

const customerCtaOverride: ButtonProps = {
  label: 'Add another pet',
  url: siteLinks.quoteAndBuy,
  iconLeft: <Plus />,
};

//
// VETS
//

const vetsCtaOverride: ButtonProps = {
  label: 'Activate free cover',
  url: siteLinks.vetActivatedCover,
  arrowRight: true,
};

//
// BREEDERS
//

const breedersCtaOverride: ButtonProps = {
  label: 'Activate free cover',
  url: siteLinks.breederClub,
  arrowRight: true,
};

//
// REHOMING (charities)
//

const rehomingCtaOverride: ButtonProps = {
  label: 'Activate free cover',
  url: siteLinks.rehomingCover,
  arrowRight: true,
};

export const ctaButtons = {
  customer: customerCtaOverride,
  vets: vetsCtaOverride,
  breeder: breedersCtaOverride,
  rehoming: rehomingCtaOverride,
};
