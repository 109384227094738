import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Bulletlist: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M11 10.5h16v-2H11v2ZM11 17.5h16v-2H11v2ZM11 24.5h16v-2H11v2ZM6 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM6 18a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3ZM6 25a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
  </Icon>
);
