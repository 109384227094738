import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Rise: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M19.195 8.5V10.5H24.775L17.195 18.08L12.2 13.085L3.5 21.785L4.915 23.2L12.205 15.91L17.2 20.905L26.19 11.91V17.49H28.19V8.5H19.195Z"
      fill="currentColor"
    />
  </Icon>
);
