import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Numberthree: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 27.5c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11Zm0-24c-7.168 0-13 5.832-13 13s5.832 13 13 13 13-5.832 13-13-5.832-13-13-13Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m17.44 14.99 1.784-3.675V11H13.54v1.785h2.94l-1.485 3.345v.315c1.095-.24 2.46 0 2.49 1.68 0 .96-.48 1.59-1.186 1.59-.718 0-1.213-.51-1.244-1.29h-1.86c.046 1.935 1.29 3.225 3.136 3.225 1.904 0 3.165-1.41 3.165-3.54 0-1.455-.646-2.835-2.056-3.12Z"
      clipRule="evenodd"
    />
  </Icon>
);
