import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Camera: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M27 9.56h-3.965l-2.13-2.68c-.19-.24-.48-.38-.785-.38h-8.245c-.305 0-.595.14-.785.38L8.96 9.56H5c-.55 0-1 .45-1 1v14.22c0 .55.45 1 1 1h22c.55 0 1-.45 1-1V10.56c0-.55-.45-1-1-1Zm-1 14.22H6V11.56h3.445c.305 0 .595-.14.785-.38l2.13-2.68h7.28l2.13 2.68c.19.24.48.38.785.38H26v12.22ZM16 11.695A5.311 5.311 0 0 0 10.695 17 5.311 5.311 0 0 0 16 22.305 5.311 5.311 0 0 0 21.305 17 5.311 5.311 0 0 0 16 11.695Zm0 8.61a3.305 3.305 0 1 1 0-6.61 3.305 3.305 0 0 1 0 6.61Z"
    />
  </Icon>
);
