import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ChevronRight: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.915 21.18 12.5 19.765l3.675-3.675-3.675-3.675L13.915 11l5.09 5.09-5.09 5.09Z"
      clipRule="evenodd"
    />
  </Icon>
);
