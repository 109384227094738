import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Shelter: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.004 4.24 3 14.181v14.318h2v-13.33l11.004-8.412L27 15.169v13.33h2V14.181L16.004 4.24Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12 18.509v10h2v-8h4v8h2v-10h-8Z"
      clipRule="evenodd"
    />
  </Icon>
);
