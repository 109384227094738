import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Parking: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M21.22 14.09c0 2.96-1.86 4.94-4.54 4.94h-2.06v3.96h-2.84V9.01h4.78c2.76 0 4.66 2.04 4.66 5.08Zm-2.72-.08c0-1.44-.76-2.4-1.82-2.4h-2.12v4.86h2.12c1.06 0 1.82-.98 1.82-2.46Z"
    />
    <path
      fill="currentColor"
      d="M24.754 5c1.24 0 2.25 1.01 2.25 2.25v17.5c0 1.24-1.01 2.25-2.25 2.25H7.251C6.01 27 5 25.99 5 24.75V7.25C5 6.01 6.01 5 7.25 5h17.498m.005-2H7.251A4.254 4.254 0 0 0 3 7.25v17.5A4.25 4.25 0 0 0 7.25 29h17.5A4.25 4.25 0 0 0 29 24.75V7.25A4.25 4.25 0 0 0 24.75 3h.004Z"
    />
  </Icon>
);
