import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ChevronLeft: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M17.59 21.18L12.5 16.09L17.59 11L19.005 12.415L15.33 16.09L19 19.765L17.59 21.18Z"
      clipRule="evenodd"
    />
  </Icon>
);
