import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Money: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m23.684 19.035-7.282-1.582c0-.031.012-.062.012-.094a4.313 4.313 0 0 0-4.312-4.308 4.311 4.311 0 0 0-3.97 2.64l-1.75-.359 1.93-8.918 17.239 3.695Zm-11.582.633a2.31 2.31 0 0 1-2.309-2.309 2.31 2.31 0 0 1 4.621 0 2.314 2.314 0 0 1-2.312 2.309ZM23.687 4.863l.637 2.942-7.265-1.559Zm2.782 3.399L25.223 2.5 12.227 5.207l-5.45-1.168L4 16.883l3.828.785c.16 2.23 2.004 4 4.274 4 1.64 0 3.05-.93 3.777-2.281l9.348 2.031 2.691-12.844ZM16.219 27.094c-.512 0-.903-.18-1.282-.621a.836.836 0 0 1-.175-.274l-.055-.129c-.055-.117-.059-.277-.059-.55 0-.852.72-1.575 1.57-1.575.856 0 1.575.723 1.575 1.575 0 .851-.719 1.574-1.574 1.574Zm0-5.149a3.578 3.578 0 0 0-3.57 3.575c0 .367 0 .859.238 1.378.133.36.36.711.582.93a3.52 3.52 0 0 0 2.75 1.266 3.578 3.578 0 0 0 3.574-3.574 3.578 3.578 0 0 0-3.574-3.575Zm0 0"
      clipRule="evenodd"
    />
  </Icon>
);
