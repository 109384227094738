import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Dog: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M25.898 7.102 26 7.8c.102.597-.102 1.199-.398 1.8C24.8 11 23 11.5 21.602 10.7l-1 1.7C23 13.698 26 13 27.3 10.601c.597-.903.8-2 .597-3.102l-.5-2.3H24C22.8 3.8 21 3 19 3c-3.5 0-6.602 2.7-6.898 6.2v1.1c0 .891.464 1.66 1.136 2.165a14.702 14.702 0 0 0-4.597 9.625C7.14 21.695 6 20.305 6 18.7H4c0 2.702 2.031 5 4.629 5.429.082 1.43.36 2.734.77 3.973l.3.699h10.5v.097h5.903V25.2c0-1.3-.903-2.3-2.204-2.3h-.796V14h-2v8.898h-1v2h3.796c.102 0 .204.102.204.301v1.7H20.3V26.8h-9.2c-.402-1.102-.5-2.301-.5-3.602 0-3.972 1.872-7.746 5.032-10.148C16.98 12.8 18 11.629 18 10.3V9.1h-2V10.2c0 .5-.398.903-.898.903S14 10.699 14 10.199v-.898c.5-2.403 2.7-4.403 5.2-4.403 1.5 0 2.8.704 3.698 1.801l.301.403Zm-9.5 18-.5-1.903c1-.199 1.704-1.097 1.704-2.097C17.602 20 16.699 19 15.5 19c-.398 0-.898.102-1.3.398l-1.098-1.597c.699-.5 1.5-.801 2.398-.801 2.2 0 4.102 1.8 4.102 4.102 0 1.898-1.301 3.597-3.204 4Zm0 0"
      clipRule="evenodd"
    />
  </Icon>
);
