// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../client.mjs';

const updateHeaders = (headers: Array<Record<string, string>>) => {
  headers.forEach((header) => {
    Object.keys(header).forEach((key) => {
      service.defaults.headers[key] = header[key];
    });
  });
};

const deleteHeaders = (headers: string[]) => {
  headers.forEach((key) => {
    delete service.defaults.headers[key];
  });
};

export { updateHeaders, deleteHeaders };
