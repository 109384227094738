import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Mobiledevice: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M21.69 2.5H10.31C8.52 2.5 7 4.025 7 5.82v21.365c0 1.815 1.46 3.32 3.31 3.32h11.385c1.79 0 3.31-1.525 3.31-3.32V5.82c0-1.795-1.52-3.32-3.31-3.32h-.005ZM23 27.18c0 .69-.625 1.32-1.31 1.32H10.31c-.73 0-1.31-.595-1.31-1.32V23.5h14v3.68Zm-7-9.485a3.992 3.992 0 0 1 3.99 3.805h-7.98A3.992 3.992 0 0 1 16 17.695Zm-1.495-4.005a1.496 1.496 0 1 1 2.992.002 1.496 1.496 0 0 1-2.992-.002ZM23 21.5h-1.01a5.992 5.992 0 0 0-3.57-5.29 3.488 3.488 0 0 0 1.075-2.515c0-1.93-1.57-3.495-3.495-3.495a3.502 3.502 0 0 0-3.495 3.495c0 .99.415 1.88 1.075 2.515a6 6 0 0 0-3.57 5.29H9v-13h14v13Zm0-15H9v-.68c0-.69.625-1.32 1.31-1.32h11.385c.685 0 1.31.63 1.31 1.32v.68H23Zm-7 21c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5-1.5.67-1.5 1.5.67 1.5 1.5 1.5Z"
    />
  </Icon>
);
