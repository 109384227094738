import { isBrowser } from './isBrowser';

export const getSiteUrl = (): string => {
  if (process.env.GATSBY_SITE_URL) {
    return process.env.GATSBY_SITE_URL;
  }
  if (isBrowser && window.location.origin) {
    return window.location.origin;
  }

  return 'http://localhost:8000';
};
