import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Numbertwo: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 27.5c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11Zm0-24c-7.168 0-13 5.832-13 13s5.832 13 13 13 13-5.832 13-13-5.832-13-13-13Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M18.04 16.625c1.71-2.79.555-5.774-1.905-5.774-1.77 0-2.85 1.184-2.835 3.239h1.785c0-.869.33-1.319.96-1.319.585 0 .9.434.9 1.29 0 .614-.345 1.214-.645 1.724l-3.105 5.235v.48h5.79v-1.95h-2.73l1.785-2.925Z"
      clipRule="evenodd"
    />
  </Icon>
);
