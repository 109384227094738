import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Cat: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m7.975 3 2.052 3.27h2.57l1.626-3.251.89 1.81c.611 1.245.728 2.801.722 3.943a16.543 16.543 0 0 1-.069 1.41c.583.4 1.051.907 1.38 1.317.183.228.335.442.453.618 1.397.2 2.62.988 3.463 1.668a12.29 12.29 0 0 1 1.595 1.556l.026.031.007.01.003.003v.001s.002.001-.778.627l.78-.626.011.014.011.015c2.536 3.405 3.054 6.883 2.778 9.573-.137 1.338-.471 2.492-.858 3.356-.193.43-.408.81-.633 1.11-.193.257-.503.608-.929.758l-.162.057H9.474a.997.997 0 0 1-.178-.016c-1.85-.335-2.94-1.71-3.22-3.31-.275-1.563.2-3.35 1.363-4.799a1 1 0 0 1 1.56 1.25c-.842 1.051-1.118 2.265-.953 3.203.153.872.666 1.486 1.526 1.672h12.82a3.914 3.914 0 0 0 .42-.743c.296-.662.577-1.607.694-2.742.23-2.25-.19-5.207-2.38-8.156l-.006-.008a8.831 8.831 0 0 0-.333-.37 10.29 10.29 0 0 0-.982-.91c-.851-.687-1.859-1.25-2.812-1.271l-.575-.013-.277-.502-.005-.008a7.039 7.039 0 0 0-.55-.797c-.376-.469-.836-.91-1.298-1.117l-.696-.313.117-.752.001-.005.003-.022a8.78 8.78 0 0 0 .056-.479c.031-.327.064-.785.066-1.3.002-.229-.003-.462-.015-.695a1 1 0 0 1-.605.203h-3.74a1 1 0 0 1-.673-.26c-.01.291-.011.606.001.942.08 2.166.703 5.268 2.95 8.883.144.232.291.462.438.691.528.826 1.05 1.642 1.42 2.524.5 1.19.742 2.507.601 4.3l-.079.996-1.993-.157.078-.997c.119-1.503-.085-2.498-.45-3.367-.299-.71-.698-1.335-1.205-2.129-.159-.25-.328-.515-.509-.805-2.44-3.926-3.158-7.37-3.25-9.866a13.894 13.894 0 0 1 .191-2.946 9.422 9.422 0 0 1 .25-1.047c.008-.028.016-.05.022-.067l.007-.02.002-.008.001-.003v-.002l.942.338-.94-.339L7.974 3ZM9.16 5.608l.001-.002v-.001.003Z"
      clipRule="evenodd"
    />
  </Icon>
);
