import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Pricing: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      d="M16 29c7.18 0 13-5.82 13-13S23.18 3 16 3 3 8.82 3 16s5.82 13 13 13Z"
    />
    <path
      fill="#fff"
      d="M17.11 11.2c-1.52 0-2.5 1.2-2.26 3.86h3.58v1.88h-3.36c.04.32.08.62.08.92 0 1-.18 1.92-.64 2.76.26-.2.58-.28.9-.28.8 0 1.72.42 2.4.42.6 0 1-.3.9-1.82h2.2c.08.54.12 1.02.08 1.48-.1 1.58-.88 2.76-2.64 2.76-.94 0-2.38-.58-3.48-.58-.44 0-.8.1-1.08.44h-2.7v-.26c1.08-1.14 1.42-2.28 1.44-3.74.02-.68-.08-1.38-.18-2.1h-1.36v-1.88h1.14c-.42-4.7 2.08-6.24 5.06-6.24 1.04 0 2.24.4 3.22.94l-.84 2.14c-.72-.48-1.6-.7-2.46-.7Z"
    />
  </Icon>
);
