import { getSrc } from 'gatsby-plugin-image';

/*
 * Receives raw GraphQL data from the CMS and parses it into valid Schema.org properties depending on the type of content.
 * Inserts site-specific values using the useSitemetadata hook where necessary.
 * Currently used on brochureware pages but could be extended to other packages/sites.
 */
export const createSchemaPropertiesByPageType = (
  data: any,
  schemaSiteMetadata: string | null,
  type:
    | 'page'
    | 'article'
    | 'blogPost'
    | 'jobAdvert'
    | 'guideListing'
    | 'guideSpeciesListing'
    | 'guideSpeciesTopicListing'
    | 'blogListing'
    | 'newsListing'
    | 'peopleListing'
    | 'authorPage'
    | 'personPage'
): Record<string, any> => {
  const schemaDataJSON: Record<string, any> = schemaSiteMetadata
    ? JSON.parse(schemaSiteMetadata)
    : {};
  let parsedSchemaData = {};
  if (data) {
    if (process.env.NODE_ENV && process.env.NODE_ENV === 'development') {
      // logDataToConsole(data as Record<string, any>); // Uncomment this to log the incoming CMS data to the console for debugging.
    }
    if (type && type === 'page' && data.page) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const parsedData = parsePage(data.page, schemaSiteMetadata || null);
      parsedSchemaData = {
        ...(parsedSchemaData || {}),
        ...(parsedData || {}),
      };
    } else if (type && ['article', 'blogPost'].includes(type) && data.page) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const parsedData = parseBlogPostOrArticle(
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        data.page,
        schemaSiteMetadata,
        type
      );
      parsedSchemaData = {
        ...(parsedSchemaData || {}),
        ...(parsedData || {}),
      };
    } else if (
      type &&
      ['blogListing', 'guideListing', 'newsListing', 'peopleListing'].includes(
        type
      ) &&
      data.page
    ) {
      const mergedData = {
        ...(data.page ? data.page : {}),
        articles: data.articles ? data.articles : undefined,
        overrideURL: data.overrideURL ? data.overrideURL : undefined,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const parsedData = parseListingPage(mergedData, schemaSiteMetadata, type);
      parsedSchemaData = {
        ...(parsedSchemaData || {}),
        ...(parsedData || {}),
      };
    } else if (
      type &&
      ['guideSpeciesListing', 'guideSpeciesTopicListing'].includes(type) &&
      data.guideListingPage
    ) {
      // We first need to merge some data from the query in GuidesAndAdviceSpeciesListing.tsx.
      const mergedData = {
        ...(data.guideListingPage ? data.guideListingPage : {}),
        articles: data.articles ? data.articles : {},
        speciesName: data.speciesName ? data.speciesName : undefined,
        topicName: data.topicName ? data.topicName : undefined,
        overrideURL: data.overrideURL ? data.overrideURL : undefined,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const parsedData = parseListingPage(mergedData, schemaSiteMetadata, type);
      parsedSchemaData = {
        ...(parsedSchemaData || {}),
        ...(parsedData || {}),
      };
    } else if (['authorPage', 'personPage'].includes(type)) {
      const mergedData = {
        ...(data.page ? data.page : {}),
        articles: data.blogArticles ? data.blogArticles : undefined,
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      const parsedData = parsePerson(mergedData, schemaSiteMetadata);
      parsedSchemaData = {
        ...(parsedSchemaData || {}),
        ...(parsedData || {}),
      };
    } else if (type && type === 'jobAdvert' && data.page) {
      parsedSchemaData = {
        ...(parsedSchemaData || {}),
        '@type': 'JobPosting',
        datePosted: data.page.publishDate ? data.page.publishDate : undefined,
        employmentType: data.page.jobType?.name
          ? data.page.jobType.name
          : undefined,
        hiringOrganization:
          schemaDataJSON && schemaDataJSON.organisation
            ? schemaDataJSON.organisation
            : undefined,
        jobLocation:
          data.page.location &&
          (data.page.location.locationName || data.page.location.address)
            ? {
                '@type': 'Place',
                name: data.page.location.locationName
                  ? data.page.location.locationName
                  : undefined,
                address: data.page.location.address
                  ? data.page.location.address
                  : undefined,
              }
            : undefined,
        title: data.page.title ? data.page.title : undefined,
        validThrough: data.page.closingDate ? data.page.closingDate : undefined,
      };
    }
  }
  return parsedSchemaData;
};

/*
 * Parses and return valid Schema.org properties for a given URL.
 * Schema.org properties for specific URLs are passed (as a string) into siteMetadata via Gatsby theme options.
 * If the Schema.org @type for a URL is "Organization" or "FinancialService" it'll source the data from the schemaData.organisation theme option property.
 * Currently used on brochureware pages but could be extended to other packages/sites.
 * Check /packages/sites/agria-brochureware/gatsby-config.ts for an example of how to structure the data.
 */
export const createSchemaPropertiesByURL = (
  url: string,
  schemaSiteMetadata: string | null
): Record<string, any> => {
  const schemaDataJSON: Record<string, any> = schemaSiteMetadata
    ? JSON.parse(schemaSiteMetadata)
    : {};
  let parsedSchemaData: Record<string, any> = {};
  if (
    url &&
    schemaDataJSON &&
    schemaDataJSON.byUrl &&
    schemaDataJSON.byUrl[url]
  ) {
    const data = schemaDataJSON.byUrl[url];
    parsedSchemaData = {
      ...data,
    };
    if (
      data['@type'] &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      ['Organization', 'FinancialService'].includes(data['@type']) &&
      schemaDataJSON.organisation
    ) {
      // Insert the organisation data if the given @type for the URL is "Organization" or "FinancialService".
      parsedSchemaData = {
        ...schemaDataJSON.organisation,
      };
    } else if (data['@type'] && data['@type'] === 'Product') {
      // If we've got a Product page type, insert the organisation data in the brand property.
      parsedSchemaData = {
        ...parsedSchemaData,
        brand: schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      };
    }
    // Add the WebPage type as a secondary type to support additional valid Schema.org properties sourced from CMS data.
    if (
      parsedSchemaData &&
      parsedSchemaData['@type'] &&
      typeof parsedSchemaData['@type'] === 'string' &&
      parsedSchemaData['@type'] !== 'WebPage'
    ) {
      parsedSchemaData = {
        ...(parsedSchemaData || {}),
        '@type': [data['@type'], 'WebPage'],
      };
    }
  }
  return parsedSchemaData;
};

/*
 * Parses an individual page.
 * Uses available siteMetadata to fill in any blanks.
 */
const parsePage = (
  data: Record<string, any>,
  schemaSiteMetadata: string | null
): Record<string, any> | undefined => {
  const schemaDataJSON: Record<string, any> = schemaSiteMetadata
    ? JSON.parse(schemaSiteMetadata)
    : {};
  if (data && Object.keys(data).length > 0 && data.name) {
    return {
      '@type': 'WebPage',
      author:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      copyrightHolder:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      copyrightYear: data.publishDate
        ? new Date(data.publishDate as string).getFullYear()
        : undefined,
      dateCreated: data.createDate ? data.createDate : undefined,
      dateModified: data.updateDate ? data.updateDate : undefined,
      datePublished: data.publishDate ? data.publishDate : undefined,
      description: parseDescription(data),
      headline: data.name ? data.name : undefined,
      image: parseImage(data),
      name: data.name ? data.name : undefined,
      publisher:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      url: parseURL(data),
    };
  }
};

/*
 * Parses an individual blog post or article.
 * Uses available siteMetadata to fill in any blanks.
 */
const parseBlogPostOrArticle = (
  data: Record<string, any>,
  schemaSiteMetadata: string | null,
  type: string
): Record<string, any> | undefined => {
  const schemaDataJSON: Record<string, any> = schemaSiteMetadata
    ? JSON.parse(schemaSiteMetadata)
    : {};
  if (data && Object.keys(data).length > 0 && data.name) {
    return {
      '@type': [type === 'blogPost' ? 'BlogPosting' : 'Article', 'WebPage'],
      // about -> The subject matter (cat, dog, horse)? Maybe add this later if the data is available.
      audience: parseAudience(data) || undefined,
      author: data.author?.name
        ? parsePerson(data.author as Record<string, any>, schemaSiteMetadata)
        : undefined,
      copyrightHolder:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      copyrightYear: data.publishDate
        ? new Date(data.publishDate as string).getFullYear()
        : undefined,
      dateCreated: data.createDate ? data.createDate : undefined,
      dateModified: data.updateDate ? data.updateDate : undefined,
      datePublished: data.publishDate ? data.publishDate : undefined,
      description: parseDescription(data),
      headline: data.name ? data.name : undefined,
      image: parseImage(data),
      // lastReviewed: TODO: https://trello.com/c/xdPtFhuA/113-add-reviewed-by-to-guides-and-advice
      name: data.name ? data.name : undefined,
      publisher:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      // reviewedBy: TODO: https://trello.com/c/xdPtFhuA/113-add-reviewed-by-to-guides-and-advice
      url: parseURL(data),
    };
  }
};

/*
 * Parses a listing page..
 * Uses available siteMetadata to fill in any blanks.
 */
const parseListingPage = (
  data: Record<string, any>,
  schemaSiteMetadata: string | null,
  type: string
): Record<string, any> | undefined => {
  const schemaDataJSON: Record<string, any> = schemaSiteMetadata
    ? JSON.parse(schemaSiteMetadata)
    : {};
  let parsedSchemaData = {};
  if (data && Object.keys(data).length > 0 && data.name) {
    const listingURL = parseURL(data);
    parsedSchemaData = {
      ...(parsedSchemaData || {}),
      '@type': [
        ['blogListing', 'newsListing'].includes(type) ? 'Blog' : 'Collection',
        'WebPage',
      ],
      '@id': listingURL || undefined,
      author:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      copyrightHolder:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      copyrightYear: data.publishDate
        ? new Date(data.publishDate as string).getFullYear()
        : undefined,
      dateCreated: data.createDate ? data.createDate : undefined,
      dateModified: data.updateDate ? data.updateDate : undefined,
      datePublished: data.publishDate ? data.publishDate : undefined,
      description: parseDescription(data),
      image: parseImage(data),
      name: data.name ? data.name : undefined,
      publisher:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      url: listingURL || undefined,
    };
    // Properties that are only valid for the blog and news listings.
    if (['blogListing', 'newsListing'].includes(type)) {
      const articlesToParse =
        data.articles && data.articles.nodes ? data.articles.nodes : [];
      parsedSchemaData = {
        ...parsedSchemaData,
        blogPost:
          articlesToParse && articlesToParse.length > 0
            ? articlesToParse.map((article: any) => {
                const parsedArticleOrBlogPost = parseBlogPostOrArticle(
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                  article,
                  schemaSiteMetadata,
                  'blogPost'
                );
                return parsedArticleOrBlogPost || undefined;
              })
            : undefined,
      };
    }
    // Properties that are only valid for the species-specific guide listings.
    if (['guideSpeciesListing', 'guideSpeciesTopicListing'].includes(type)) {
      if (data.speciesName) {
        parsedSchemaData = {
          ...parsedSchemaData,
          name: data.speciesName
            ? `Guides and advice for ${data.speciesName} owners.`
            : undefined,
          description: data.speciesName
            ? `Guides and advice for ${data.speciesName} owners.`
            : undefined,
          audience: parseAudience(data) || undefined,
        };
      }
    }
  }
  return parsedSchemaData || undefined;
};

/*
 * Parses and returns person properties.
 * Uses available siteMetadata to fill in any blanks.
 */
const parsePerson = (
  data: Record<string, any>,
  schemaSiteMetadata: string | null
): Record<string, any> | undefined => {
  const schemaDataJSON: Record<string, any> = schemaSiteMetadata
    ? JSON.parse(schemaSiteMetadata)
    : {};
  if (data && Object.keys(data).length > 0) {
    return {
      '@type': 'Person',
      // eslint-disable-next-line no-nested-ternary
      name: data.name
        ? data.name
        : data.personName
        ? data.personName
        : undefined,
      affiliation:
        schemaDataJSON && schemaDataJSON.organisation
          ? schemaDataJSON.organisation
          : undefined,
      description: data.bio ? data.bio : undefined,
      // eslint-disable-next-line no-nested-ternary
      jobTitle: data.position
        ? data.position
        : data.personRole
        ? data.personRole
        : undefined,
      workLocation: data.location ? data.location : undefined,
      worksFor: (function () {
        let worksForObject: Record<string, any> = {
          '@type': 'Organization',
        };
        if (data.company) {
          worksForObject = {
            ...worksForObject,
            name: data.company,
          };
        } else if (
          data.personCompany &&
          !data.personCompany.includes('Agria')
        ) {
          worksForObject = {
            ...worksForObject,
            name: data.personCompany,
          };
        } else if (schemaDataJSON && schemaDataJSON.organisation) {
          worksForObject = {
            ...worksForObject,
            ...schemaDataJSON.organisation,
          };
        }
        return worksForObject;
      })(),
      url: parseURL(data),
    };
  }
};

/*
 * Parses and returns a description for the content.
 */
const parseDescription = (data: Record<string, any>): string | undefined => {
  if (data) {
    if (data.page?.subheading) {
      return data.page.subheading;
    }
    if (data.subheading) {
      return data.subheading;
    }
    if (data.page?.subHeading) {
      return data.page.subHeading;
    }
    if (data.subHeading) {
      return data.subHeading;
    }
    if (data.page?.heroDescription) {
      return data.page.heroDescription;
    }
    if (data.heroDescription) {
      return data.heroDescription;
    }
    if (data.page?.properties?.seo?.pageDescription) {
      return data.page.properties.seo.pageDescription;
    }
    if (data.properties?.seo?.pageDescription) {
      return data.properties.seo.pageDescription;
    }
    if (data.page?.properties?.feed?.shortDescription) {
      return data.page.properties.feed.shortDescription;
    }
    if (data.properties?.feed?.shortDescription) {
      return data.properties.feed.shortDescription;
    }
  }
};

/*
 * Parses and returns the URL for the content.
 * Prepends the domain to the URL if necessary.
 * Handles canonical URL overrides.
 */
const parseURL = (data: Record<string, any>): string | undefined => {
  if (data.overrideURL) {
    return data.overrideURL;
  }
  if (data.page?.properties?.seo?.canonicalUrl) {
    return data.page.properties.seo.canonicalUrl;
  }
  if (data.properties?.seo?.canonicalUrl) {
    return data.properties.seo.canonicalUrl;
  }
  if (data.page?.url && process.env.GATSBY_SITE_URL) {
    return process.env.GATSBY_SITE_URL + (data.page.url as string);
  }
  if (data.url && process.env.GATSBY_SITE_URL) {
    return process.env.GATSBY_SITE_URL + (data.url as string);
  }
  if (data.guideUrl && process.env.GATSBY_SITE_URL) {
    return process.env.GATSBY_SITE_URL + (data.guideUrl as string);
  }
  if (data.newsUrl && process.env.GATSBY_SITE_URL) {
    return process.env.GATSBY_SITE_URL + (data.newsUrl as string);
  }
  if (data.blogUrl && process.env.GATSBY_SITE_URL) {
    return process.env.GATSBY_SITE_URL + (data.blogUrl as string);
  }
};

/*
 * Parses and returns a URL for the representative image.
 */
const parseImage = (data: Record<string, any>): string | undefined => {
  let imageObject: Record<string, any> | undefined;
  if (data.page?.heroImage?.image?.localFile) {
    imageObject = data.page.heroImage.image.localFile;
  } else if (data.heroImage?.image?.localFile) {
    imageObject = data.heroImage.image.localFile;
  } else if (data.page?.properties?.feed?.featuredImage?.image?.localFile) {
    imageObject = data.page.properties.feed.featuredImage.image.localFile;
  } else if (data.properties?.feed?.featuredImage?.image?.localFile) {
    imageObject = data.properties.feed.featuredImage.image.localFile;
  } else if (
    data.page?.properties?.heroComponents?.[0]?.block?.components?.[0]?.image
      ?.image?.localFile
  ) {
    imageObject =
      data.page.properties.heroComponents[0].block.components[0].image.image
        .localFile;
  } else if (
    data.properties?.heroComponents?.[0]?.block?.components?.[0]?.image?.image
      ?.localFile
  ) {
    imageObject =
      data.properties.heroComponents[0].block.components[0].image.image
        .localFile;
  }
  if (imageObject) {
    const imageSource = getSrc(imageObject);
    if (imageSource && process.env.GATSBY_SITE_URL) {
      return process.env.GATSBY_SITE_URL + imageSource;
    }
  }
};

/*
 * Parses the data and finds whether we can attribute the content to a specific audience.
 */
const parseAudience = (data: Record<string, any>): Record<string, any> => {
  let array: Array<string> = [];
  if (data.page?.allSpecies) {
    array = data.page.allSpecies.map((species: any) => species.name);
  }
  if (data.allSpecies) {
    array = data.allSpecies.map((species: any) => species.name);
  }
  if (data.speciesName) {
    array = [data.speciesName];
  }
  let audienceObject: Record<string, any> = {
    '@type': 'Audience',
    audienceType: 'Pet owners',
  };
  if (array && array.length > 0) {
    audienceObject = {
      ...(audienceObject || {}),
      audienceType: `${listToString(array)} owners` || 'Pet owners',
    };
  }
  return audienceObject;
};

/*
 * Parses a list into a string.
 * Currently used to join a list of animal species into a string.
 * (I didn't use intl.listFormat as it doesn't support the Oxford Comma and I like those.)
 */
const listToString = (array: Array<string>): string | undefined => {
  if (array && array.length === 2) {
    return array.join(' and ');
  }
  if (array.length > 2) {
    return array
      .slice(0, array.length - 1)
      .concat(`and ${array.slice(-1)}`)
      .join(', ');
  }
  return array.join(', ');
};

/*
 * Sorts, parses, and logs the CMS data to the console for debugging.
 * This is only used to make it easier see which properties are included in the incoming data.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const logDataToConsole = (data: Record<string, any>) => {
  const sortObject = (object: Record<string, any>): Record<string, any> =>
    Object.keys(object)
      .sort()
      .reduce((obj: Record<string, any>, key) => {
        obj[key] = object[key];
        return obj;
      }, {});
  const sortObjectRecursively = (
    object: Record<string, any>
  ): Record<string, any> => {
    for (const [key, value] of Object.entries(object)) {
      if (value && typeof value === 'object') {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        object[key] = sortObject(value);
      }
    }
    return sortObject(object);
  };
  if (data && typeof data === 'object') {
    const dataToSort = structuredClone(data);
    let sortedData = sortObjectRecursively(dataToSort || {});
    if (sortedData.page && sortedData.page.parentPage) {
      sortedData = {
        ...(sortedData || {}),
        page: {
          ...(sortedData.page ? sortedData.page : {}),
          parentPage: null, // Remove this from the output as it's needlessly large.
        },
      };
    }
    console.log(JSON.stringify(sortedData, null, 2));
  }
};
