import React, { createContext, useContext, ReactNode, useMemo } from 'react';

interface PageContextProps {
  pageID: string;
  stickyCallToAction?: string;
  overrideStickyCTAButton?: {
    label: string;
    url: string;
    target: string;
  };
}

export const PageContext = createContext<PageContextProps | undefined>(
  undefined
);

export const PageProvider: React.FC<
  PageContextProps & { children: ReactNode }
> = ({ pageID, stickyCallToAction, overrideStickyCTAButton, children }) => {
  const contextValue = useMemo(
    () => ({
      pageID,
      stickyCallToAction,
      overrideStickyCTAButton,
    }),
    [pageID, stickyCallToAction, overrideStickyCTAButton]
  );
  return (
    <PageContext.Provider value={contextValue}>{children}</PageContext.Provider>
  );
};

export const usePage = (): PageContextProps => {
  const context = useContext(PageContext);
  if (!context) {
    throw new Error('usePage must be used within a PageProvider');
  }
  return context;
};
