import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const ClusterPin: FC<IconProps> = (props) => (
  <Icon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 40 49"
    >
      <rect
        width="33"
        height="33"
        x="6.5"
        y=".5"
        fill="currentColor"
        rx="1.5"
      />
      <rect width="33" height="33" x="6.5" y=".5" stroke="#fff" rx="1.5" />
      <rect
        width="33"
        height="33"
        x="3.5"
        y="3.5"
        fill="currentColor"
        rx="1.5"
      />
      <rect width="33" height="33" x="3.5" y="3.5" stroke="#fff" rx="1.5" />
      <rect
        width="33"
        height="33"
        x=".5"
        y="6.5"
        fill="currentColor"
        rx="1.5"
      />
      <rect width="33" height="33" x=".5" y="6.5" stroke="#fff" rx="1.5" />
      <path fill="currentColor" d="M22 39H12l5 8 5-8Z" />
    </svg>
  </Icon>
);
