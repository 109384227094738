import { AffiliateCategory } from '../types/types';

interface Category {
  Id: string;
  Name: string;
}

export const getAffiliateCategoryByTypeId = (
  id: string,
  AppointedRepresentativeStatusId: string,
  categoryArray: Category[]
): AffiliateCategory => {
  // Define an object to map similar IDs to their generic category
  const idToCategoryMap: Record<string, AffiliateCategory> = {
    '5424f58c-2c3a-4f02-a748-823cc5f2daa2': 'Vet', // Vet
    '5603fa8a-5fe7-47b9-a07c-efe11f8a5f7f': 'Vet', // Vet Partner
    '7e1f75d8-a9fe-4ba8-af78-4120c26a26c7': 'Vet', // Vet Sector Specialist
    '008e96b9-8d90-493d-9c3a-bc04d81c07ad': 'Vet', // Overseas Vet (included with Vet)
    'a04b49c9-e8ee-4477-a5bd-e40a9a446183': 'Breeder', // Breeder
    '466385a7-c330-404f-bf6a-305a751b5eb5': 'Breeder', // Breeder (Guest)
    '6eed0231-1786-4401-8237-c43dcf4930e4': 'Breeder', // Breeder agent
    '5e753897-384c-474a-a590-de2a70b9f146': 'Breeder', // Breeder Donation Charity
    '0021c8c7-4906-4951-bd17-496873c620ad': 'Charity', // Charity - Anilog
    'd28f01f3-a3b4-4ae9-8dc2-6fb91b59987c': 'Charity', // Charity - Direct
    '3f10dce8-f19b-47c9-ae36-18dcf982d166': 'Event', // Event
    '5590e894-2697-4b00-99f0-af79cee7835b': 'Agria Partner', // Agria Partner
    '5afa8104-d5d0-4a54-b458-7fdd6bdc9ae8': 'Agria Staff', // Agria Staff
    'f2c86dc5-604e-43a2-addb-bc45bd222777': 'Brand Owner', // Brand Owner
  };

  // Check if the given ID is in the map
  if (idToCategoryMap[id] !== undefined) {
    // Categorise vets further by affiliate/non-affiliate
    // 'C1E3ED2A-5C2B-4047-AA90-F24CD4FFB6B5' // Agria activated
    // '41422B49-CF35-4917-8329-18B68C818E6C' // Owner activated
    // '8DA68463-0134-4A4A-8412-870A022693B7' // vet activated
    if (idToCategoryMap[id] === 'Vet') {
      if (
        AppointedRepresentativeStatusId !==
          'c1e3ed2a-5c2b-4047-aa90-f24cd4ffb6b5' &&
        AppointedRepresentativeStatusId !==
          '41422b49-cf35-4917-8329-18b68c818e6c' &&
        AppointedRepresentativeStatusId !==
          '8da68463-0134-4a4a-8412-870a022693b7'
      ) {
        return 'Non-affiliate Vet';
      }

      return 'Vet';
    }
    return idToCategoryMap[id];
  }

  // If the ID is not directly in the map, check for similar IDs
  for (const item of categoryArray) {
    // Compare the first 8 characters of the ID (you can adjust this as needed)
    if (id.startsWith(item.Id.substring(0, 8))) {
      return item.Name as AffiliateCategory;
    }
  }

  // If no match is found, return a default category or null
  return 'undefined';
};
