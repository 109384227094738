// eslint-disable-next-line import/no-extraneous-dependencies
import CryptoJS from 'crypto-js';

const SECRET_KEY: string | undefined =
  process.env.GATSBY_MY_ACCOUNT_ENCRYPTION_KEY;

export const encryptData = (data: string): string | object => {
  if (!SECRET_KEY) {
    throw new Error('Encryption key not found');
  }
  return CryptoJS.AES.encrypt(data, SECRET_KEY).toString();
};

export const decryptData = (ciphertext: string): string | object => {
  if (!SECRET_KEY) {
    throw new Error('Encryption key not found');
  }
  const bytes = CryptoJS.AES.decrypt(ciphertext, SECRET_KEY);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8) as string);
};
