import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Numberone: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 27.5c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11Zm0-24c-7.168 0-13 5.832-13 13s5.832 13 13 13 13-5.832 13-13-5.832-13-13-13Z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16.979 11h-3.3v1.755h1.26v7.02H13.5V21.5h4.964v-1.725h-1.485V11Z"
      clipRule="evenodd"
    />
  </Icon>
);
