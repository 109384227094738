import { useMutation } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore: MJS workaround to allow in JS file
import { service } from '../../client.mjs';
import { ApiResponse } from '../../types';
import { dataOrError } from '../../utils/dataOrError';

interface UpdateCustomerFlagRequest {
  customerId: string;
  flagId: string;
}

const sendCustomerFlag = async ({
  customerId,
  flagId,
}: UpdateCustomerFlagRequest) => {
  const { data } = await service.post<ApiResponse<null>>(
    `/Entra/Customer/UpdateCustomerFlag?flagId=${flagId}&customerId=${customerId}`
  );

  return dataOrError<null>(data);
};

export function useCustomerUpdateFlag() {
  return useMutation({
    mutationFn: sendCustomerFlag,
    mutationKey: ['sendCustomerFlagEntra'],
  });
}
