import React, { FC } from 'react';
import { Icon, IconProps } from '../Icon';

export const Settings: FC<IconProps> = (props) => (
  <Icon {...props}>
    <path
      d="M22.02 21.54C22.78 20.645 23.39 19.61 23.785 18.555H23.79L24.035 17.905H27.01V14.315H23.98L23.77 13.595C23.465 12.565 22.92 11.65 22.055 10.71L21.555 10.17L23.065 7.56L19.945 5.735L18.43 8.35L17.7 8.165C17.195 8.035 16.58 7.97 16.005 7.97C15.43 7.97 14.915 8.03 14.285 8.17L13.57 8.33L12.065 5.73L8.945 7.555L10.44 10.135L9.985 10.675C9.225 11.57 8.615 12.605 8.22 13.66L7.975 14.31H5V17.9H8.025L8.235 18.62C8.54 19.65 9.085 20.565 9.95 21.505L10.45 22.045L8.94 24.655L12.06 26.48L13.575 23.865L14.305 24.05C14.81 24.18 15.425 24.245 16 24.245C16.575 24.245 17.09 24.185 17.72 24.045L18.435 23.885L19.94 26.48L23.06 24.655L21.565 22.075L22.02 21.54ZM29 19.9V19.905L29.005 19.9H29ZM29 12.315V19.9H25.395C25.025 20.73 24.55 21.53 23.995 22.27L25.79 25.375L19.22 29.215L17.43 26.125C16.455 26.275 15.485 26.27 14.57 26.13L12.785 29.215L6.215 25.375L7.995 22.3C7.365 21.53 6.895 20.745 6.565 19.905H3V12.315H6.61C6.98 11.49 7.455 10.685 8.01 9.945L6.215 6.84L12.78 2.995L14.57 6.085C15.54 5.94 16.515 5.945 17.43 6.085L19.215 3L25.785 6.845L24.005 9.92C24.64 10.69 25.105 11.475 25.435 12.315H29ZM13.87 16.145C13.87 17.32 14.825 18.275 16 18.275C17.175 18.275 18.13 17.32 18.13 16.145C18.13 14.97 17.175 14.015 16 14.015C14.825 14.015 13.87 14.97 13.87 16.145ZM11.87 16.145C11.87 13.87 13.72 12.015 16 12.015C18.275 12.015 20.13 13.87 20.13 16.145C20.13 18.42 18.275 20.275 16 20.275C13.725 20.275 11.87 18.42 11.87 16.145Z"
      fill="currentColor"
    />
  </Icon>
);
